(function($) {
 $.fn.shorten = function (settings) {

   var config = {
     showChars: 100,
     ellipsesText: '...',
     moreText: "Show full review",
     lessText: "Hide full review"
   };

   if (settings) {
     $.extend(config, settings);
   }

   $(document).off("click", '.morelink');

		$(document).on({click: function () {

				var $this = $(this);
				if ($this.hasClass('less')) {
          $this.attr("aria-expanded", "false");
					$this.removeClass('less');
  				$this.prev().attr("aria-hidden", "true");
					$this.html(config.moreText);
				} else {
					$this.addClass('less');
          $this.attr("aria-expanded", "true");
  				$this.prev().attr("aria-hidden", "false");
					$this.html(config.lessText);
				}
				$this.parent().prev().toggle();
				$this.prev().toggle();
				return false;
			}
		}, '.morelink');

		return this.each(function () {
			var $this = $(this);
			if($this.hasClass("shortened")) return;

			$this.addClass("shortened");
			var content = $this.html();
			if (content.length > config.showChars) {
				var c = content.substr(0, config.showChars);
				var h = content.substr(config.showChars, content.length - config.showChars);
        var to = $(this).attr("id") + '-full';
				var html = c + '<span class="moreellipses" aria-hidden="true">' + config.ellipsesText + ' </span><span class="morecontent"><span id ="' + to + '">' + h + '</span> <button aria-controls="' + to + '" aria-expanded="false" class="morelink btn btn-outline-primary">' + config.moreText + '</button></span>';
				$this.html(html);
				$(".morecontent span").hide();
			}
		});

	};

 })(jQuery);
